@import '~antd/dist/antd.css';

#components-layout-demo-side {
    background: rgba(255, 255, 255, 0.3);
}

.header-layout {
    background: #fff;
}

.verigram-table {
    overflow-x: auto;
}

.verigram-table .ant-spin-container {
    width: 100%;
}